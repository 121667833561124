import { MainButton } from '../../../../commons/MainButton'

import {
  Action,
  Container,
  Content,
  Image,
  Text,
  Title,
} from './SectionOne.styles'
import { LeadModel } from '../../../../../types/leadModel'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { useEffect } from 'react'

import SectionImage from '@/../../src/assets/images/section-one-image.png'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionOne = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-one')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-one',
        'section-one-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="section-one">
      <Content>
        <Title>Soluções Contábeis que Impulsionam Seu Negócio. </Title>
        <Text>
          Alcance novos patamares de crescimento com um parceiro contábil que
          cuida de tudo para você. Desde a abertura da sua empresa até a gestão
          completa das suas finanças, nossa equipe especializada está ao seu
          lado em cada etapa.
        </Text>
        <Action>
          <MainButton
            title="Solicitar uma Consultoria Gratuita"
            onClick={() => {
              HandleLead('click', 'section-one', 'section-one-main-button')
            }}
            onMouseOver={() => {
              HandleLead('mouse-over', 'section-one', 'section-one-main-button')
            }}
          />
        </Action>
      </Content>
      <Image src={SectionImage} />
    </Container>
  )
}
