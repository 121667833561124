import { Button } from './SecondaryButton.styles'

interface Props {
  title: string
  action: () => void
}

export const SecondaryButton = ({ title, action }: Props) => {
  return <Button onClick={action}>{title}</Button>
}
